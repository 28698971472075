import { Component, OnInit} from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { switchMap, catchError, map } from 'rxjs/operators';
import { interval } from 'rxjs';
import { IBrand,IBrandHF} from '../common/ibrand';
import { RedactReplyServiceService } from '../service/redact-reply-service.service';
 import { AppError } from '../common/class/app-error';
// import { of, Observable } from 'rxjs';
// import { map, catchError } from 'rxjs/operators';
import { SubSink } from '../../../node_modules/subsink';
import {  HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { IRedact,IRedactReply,Redact,RedactReply,IRedactHF ,IEmailVefifyHF,IEmailVefify,EmailVefify,IBrandDetailsHF,IBrandDetails,BrandDetails, ICountryCodeDetails, CountryCodeDetails, ICountryDialCodeForSenderAddress} from '../class/redact';
import { Router ,ActivatedRoute} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { timer, Subscription } from "rxjs";
//import { stringify } from 'querystring';
import { AI_AutoLock_Access_Check } from '../service/AutoLock_Access_Check/AI_AutoLock_Access_Check';
import { AutoLockRequestModel } from '../class/AutoLockRequestModel';
import { SendAccessControlNoticeRequestModel } from '../class/SendAccessControlNoticeRequestModel';
import { DestinationDetails } from '../class/DestinationDetails';
import { ResultContent } from '../class/AutoLockApiResponse';
import { of } from 'rxjs';
import { Location } from '@angular/common';
import { AppConfigService } from '../app-config.service';

@Component({
  selector: 'app-text-view',
  templateUrl: './text-view.component.html',
  styleUrls: ['./text-view.component.scss']
})
export class TextViewComponent implements OnInit {
 // enableEdit: boolean = true;
 mySubscription: Subscription;
 logoId:string;
 selectedLanguage:string;
  hideElement = false;
  isVerified=false;
  isSMSOptionEnabled: boolean = false;
  destShortURL:string;
  emailId:string;
  phoneNumber:string;
  countryCode:string;
  HeaderHtml: SafeHtml;
  public redact= new Redact;
  public emailVerfication=new EmailVefify;
  public brandDetails=new BrandDetails;
  public countryCodeDetails = new CountryCodeDetails;
  public filteredCountries: CountryCodeDetails[] = [];
  private subs = new SubSink();
  loginForm: FormGroup;
  loginFormSMS: FormGroup;
  public copied=false;
  showLogo:boolean=true;
  emailDispatchOption : number;
  companyLogoURL:string="";
  countDown:Subscription;
  confidentialityStatement:string;
  counter :number;
  tick = 1000;
  subscription: Subscription;
  public arrayRedactData :Array<RedactReply>;
  DialCodeForSenderAddress: string;
  selectedDialCode: string;
  isAutoLock_AccessDenied : boolean ;
  accessDeniedText : string;
  isDisappearInk: boolean;
  contactSenderText: string;
  productLine:string;
  constructor(private formBuilder: FormBuilder,private user: RedactReplyServiceService, private AI_Autolock_user: AI_AutoLock_Access_Check, private http: HttpClient,private router:Router, private route: ActivatedRoute
    ,private translate: TranslateService, private location: Location
    ,private config: AppConfigService) {
   this.route.params.subscribe( params => this.destShortURL=(params.shortURL));
   this.productLine = this.config.productLine;
   }
public conditionvalue:number=0;
//emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
//emailPattern="^(?!\\.)(?!.*?[.]{2})|[#!_%&$^+-][a-z0-9.!#_%'&^$?!.\]*{}\[+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
// '^?!.\]*{}\[
  //emailPattern="^(?!\\.)(?!\\')(?!\\^)(?!\\?)(?!\\])(?!\\*)(?!\\{)(?!\\})(?!\\[)(?!.*?[.]{2})|[#!_%&$^+-][a-z0-9.!#_%'&^$?!.\]*{}\[+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
  //emailPattern="^(?!.*?[.]{2})[a-z0-9.!#_%'&^$?!.\]@*{}\[/|=+-]+[a-z0-9.-]+\.[a-z]{2,4}$"
 //emailPattern="^(?!.*?\.\.)+|([a-z0-9.!#_%'&^~$?!\[\]/*{}|/=+-])+@[a-z0-9.-]+\.[a-z]{2,4}$"
 emailPattern = "^[a-zA-Z0-9.!#_%'&^$?\\-/+=]*[a-zA-Z0-9.!#_%'&^$?\\-/+=]+@[a-zA-Z\\u0080-\\uFFFF0-9_-]+\\.[a-zA-Z]{2,4}(?:\.[a-zA-Z]{2})?$"; 
 phoneNumberPattern = "^[0-9+\\- ]+$";

  ngOnInit() {
    this.getBrandingDetails();

    // Initialize the destination details and result content objects
    var destDetails = new DestinationDetails();
    var resultContent = new ResultContent();

    // Detect client information
    this.detectClientInfo().then(clientInfo => {
      const { ipAddress, userAgent, referer, language } = clientInfo;
      console.log('Client Info:', ipAddress, userAgent, referer, language);

      // Get destination details independently
      this.AI_Autolock_user.getDestinationDetails(this.destShortURL).subscribe(
        destinationDetails => {
          if (destinationDetails) {
            destDetails.MessageId = destinationDetails.MessageId;
            destDetails.DestinationAddress = destinationDetails.DestinationAddress;
            destDetails.SenderAddress = destinationDetails.SenderAddress;
            destDetails.MessageLanguage = destinationDetails.MessageLanguage;
            destDetails.MessageSubject = destinationDetails.MessageSubject;
            destDetails.MessageCreatedDate = destinationDetails.MessageCreatedDate;
            destDetails.EMailDispatchOption = destinationDetails.EMailDispatchOption;
            destDetails.DestinationName = destinationDetails.DestinationName
            destDetails.PhoneNumber = destinationDetails.PhoneNumber; //S1-3032

            // Prepare auto lock request model
            const autoLockRequest: AutoLockRequestModel = {
              IpAddress: ipAddress,
              SenderAddress: destDetails.SenderAddress,
              RecipientAddress: destDetails.DestinationAddress != null ? destDetails.DestinationAddress : " " ,
              //ProductLine: "RMail",
              ProductLine: this.productLine,
              ProductFeature: "Redact",
              ReferenceId: destDetails.MessageId,
              Http_UserAgent: userAgent,
              HTTP_Referer: referer,
              Browser_Language: language,
              RecipientType: destDetails.EMailDispatchOption == 2 ? 1 : 0,  //S1-3032
              RecipientMobile: destDetails.DestinationName + " <"+ destDetails.PhoneNumber +">"
            };

            // Call auto lock access check
            this.AI_Autolock_user.getautoLockAccess(autoLockRequest).subscribe(
              autoLockResponse => {
                if (autoLockResponse && autoLockResponse.resultContent) {
                  resultContent = autoLockResponse.resultContent;

                  if (resultContent.lock) {
                    // Prepare access control notice request model
                    const accessControlNoticeRequest: SendAccessControlNoticeRequestModel = {
                      SenderAddress: destDetails.SenderAddress,
                      RecipientAddress:destDetails.EMailDispatchOption == 2 ? destDetails.DestinationName + " <"+destDetails.PhoneNumber+">" : destDetails.DestinationAddress,
                      MessageId: destDetails.MessageId,
                      Location: `${resultContent.city}, ${resultContent.regionCode}`,
                      Country: resultContent.countryName,
                      Network: resultContent.network,
                      Feature: "RMail - Redact",
                      OriginalSentDate: destDetails.MessageCreatedDate,
                      IpAddress: ipAddress,
                      ContinentCode: resultContent.continentCode,
                      AlertType: resultContent.riskLevel === 'Y' ? 'Yellow' : 'Red',
                      Language: destDetails.MessageLanguage,
                      Subject : destDetails.MessageSubject
                    };

                    // Send access control notice
                    this.AI_Autolock_user.sendAccessControlNotice(accessControlNoticeRequest).subscribe(
                      response => {
                        console.log('Access Control Notice Sent:', response);
                        this.isAutoLock_AccessDenied = true;
                        this.accessDeniedText = "Access Denied";
                        this.contactSenderText = "Please contact the sender for further information.";
                        this.isDisappearInk = false;
                        //this.router.navigate(['accessdenied']);
                        //this.router.navigateByUrl('/accessdenied', { skipLocationChange: false });
                        
                        //const queryString = `?destShortURL=${encodeURIComponent(this.destShortURL)}&ViewType=RD`;
                        //this.location.go('/accessdenied');
                        this.location.go(`/accessdenied/${this.destShortURL}`+"/RD");
                        //this.router.navigateByUrl(`/accessdenied/${this.destShortURL}`+"/RD");
                        return;
                      },
                      error => {
                        console.error('Error sending access control notice:', error);
                      }
                    );
                  } else {
                    console.log('Access is allowed');
                    this.isAutoLock_AccessDenied = false;
                    this.accessDeniedText = "";
                    this.contactSenderText = "";
                    this.isDisappearInk = false;
                    this.getCountryDialCodeList();
                    this.loginForm = this.formBuilder.group({
                      emailId:  [null, [Validators.required,Validators.pattern(this.emailPattern)]],
                    });
                    this.loginFormSMS = this.formBuilder.group({
                      phoneNumber:  [null, [Validators.required,Validators.pattern(this.phoneNumberPattern)]],
                      countryDialCode: [null, [Validators.required]]
                    });
                  }
                  if (!resultContent.enabled)
                  {
                    console.log('Autolock feature is not enabled');
                    this.isAutoLock_AccessDenied = false;
                    this.accessDeniedText = "";
                    this.contactSenderText = "";
                    this.isDisappearInk = false;
                    this.getCountryDialCodeList();
                    this.loginForm = this.formBuilder.group({
                      emailId:  [null, [Validators.required,Validators.pattern(this.emailPattern)]],
                    });
                    this.loginFormSMS = this.formBuilder.group({
                      phoneNumber:  [null, [Validators.required,Validators.pattern(this.phoneNumberPattern)]],
                      countryDialCode: [null, [Validators.required]]
                    });
                  }
                }
                else
                {
                    this.isAutoLock_AccessDenied = false;
                    this.accessDeniedText = "";
                    this.getCountryDialCodeList();
                    this.loginForm = this.formBuilder.group({
                      emailId:  [null, [Validators.required,Validators.pattern(this.emailPattern)]],
                    });
                    this.loginFormSMS = this.formBuilder.group({
                      phoneNumber:  [null, [Validators.required,Validators.pattern(this.phoneNumberPattern)]],
                      countryDialCode: [null, [Validators.required]]
                    });
                }
              },
              error => {
                console.error('Error during auto lock access check:', error);
              }
            );
          }
          else
          {
              this.isAutoLock_AccessDenied = false;
              this.accessDeniedText = "";
              this.getCountryDialCodeList();
              this.loginForm = this.formBuilder.group({
                emailId:  [null, [Validators.required,Validators.pattern(this.emailPattern)]],
              });
              this.loginFormSMS = this.formBuilder.group({
                phoneNumber:  [null, [Validators.required,Validators.pattern(this.phoneNumberPattern)]],
                countryDialCode: [null, [Validators.required]]
              });
          }
        },
        error => {
          console.error('Error getting destination details:', error);
        }
      );
    });


    

    
    ///redact
  //   this.getRedactTextView();
  //    this.countDown = timer(0, this.tick)
  //   .subscribe(() => --this.redact.TimeAllowed)
  //   this.mySubscription= interval(60000).subscribe((x =>{
  // if(  this.redact.TimeAllowed >0){
  //       this.subs.sink = this.user.apiRedactTextViewDuration(this.logoId)
  //     .subscribe(resp => {
     
  //     });
  //   }
  // }));
    // this.sub = Observable.interval(10000)
    // .subscribe((val) => { console.log('called'); });
 
  }

  // async detectClientInfo(): Promise<{ ipAddress: string, userAgent: string, referer: string, language: string }> {
  //   const ipAddress = await this.getIpAddress();//"183.82.7.117";//
  //   const userAgent = navigator.userAgent;
  //   const referer = document.referrer;
  //   const language = navigator.language || (navigator as any).userLanguage;

  //   return { ipAddress, userAgent, referer, language };
  // }

  // getIpAddress(): Promise<string> {
  //   return this.http.get<{ ip: string }>('https://api64.ipify.org?format=json').pipe(
  //     map(response => response.ip)
  //   ).toPromise();
  // }

  async detectClientInfo(): Promise<{ ipAddress: string, userAgent: string, referer: string, language: string }> {
    const serverVariables = await this.AI_Autolock_user.getServerVariables().toPromise();

    if (serverVariables) {
      const { IPAddress, UserAgent, Referer, BrowserLanguage } = serverVariables;
      return {
        ipAddress: IPAddress || null,
        userAgent: UserAgent || null,
        referer: Referer || null,
        language: BrowserLanguage || null
      };
    } else {
      return {
        ipAddress: null,
        userAgent: null,
        referer: null,
        language: null
      };
    }
  }

  
  getBrandingDetails()
  {
   this.subs.sink = this.user.postUserInformation(this.destShortURL,"RD")
    .subscribe(resp => {
             if (!(resp instanceof AppError)) {      
          const response = resp as IBrandDetailsHF;
           const result = response.ResultContent;
           this.brandDetails=result;
          
           this.translate.use(this.brandDetails.Language);
           localStorage.setItem('Langauge', this.brandDetails.Language);
           this.emailDispatchOption = this.brandDetails.EMailDispatchOption;
           this.isSMSOptionEnabled = this.emailDispatchOption == 2 ? true : false;
           if (this.isSMSOptionEnabled == true) 
           {
            this.subs.sink = this.user.postCountryDialCodeForSenderAddress(this.destShortURL)
            .subscribe(resp => {
              if(!(resp instanceof AppError)){
                const response = resp as ICountryDialCodeForSenderAddress;
                this.DialCodeForSenderAddress = response.DialCode;
              }
            })
           }
           if( this.brandDetails.LogoSrc==null ||this.brandDetails.LogoSrc=="")
           {
                   this.showLogo=false;
           }
           else
           {
            this.showLogo=true;
            this.companyLogoURL=this.brandDetails.LogoSrc;
           }}});
  }
 
  EmailVerification(emailID:string,phoneNumber:string, countryDialCode:string )
  {
 
    this.subs.sink = this.user.postUserVerification(this.destShortURL,emailID,phoneNumber, countryDialCode,"RD")
    .subscribe(resp => {
             if (!(resp instanceof AppError)) {      
          const response = resp as IEmailVefifyHF;
           const result = response.ResultContent;
           this.emailVerfication=result;
           if( this.emailVerfication.Verified.toString()=="true")
         {
          this.getRedactTextView();
          this.countDown = timer(0, this.tick)
         .subscribe(() => --this.redact.TimeAllowed)
         this.mySubscription= interval(60000).subscribe((x =>{
       if(  this.redact.TimeAllowed >0){
             this.subs.sink = this.user.apiRedactTextViewDuration(this.logoId)
           .subscribe(resp => {
          
           });
         }
       }));
       this.isVerified=true;
         }
         else{
           this.validEmail=false;
           this.validNumber = false;
         }
         
      //     localStorage.setItem('disapperInk',null);
             }});
    
  }
  getRedactTextView() {
    //     this.subs.sink = this.user.getRedactTextView(this.destShortURL)
       this.subs.sink = this.user.postRedactTextView(this.destShortURL)
    .subscribe(resp => {
             if (!(resp instanceof AppError)) {      
          const response = resp as IRedactHF;
           const result = response.ResultContent;
           this.redact=result;
      //     localStorage.setItem('disapperInk',null);
     localStorage.setItem('redact', JSON.stringify( this.redact));

         if( this.redact.LogoSrc==null ||this.redact.LogoSrc=="")
         {
                 this.showLogo=false;
         }
         else
         {
          this.showLogo=true;
          this.companyLogoURL=this.redact.LogoSrc;
         }
         this.confidentialityStatement=this.redact.ConfidentialityStatement;
         const source = interval(this.redact.TimeAllowed* 1000);
         this.redact.resultContents;
         this.subscription = source.subscribe(val => this.redirectToTimeRemaining());
        
         let count :number=1;
         for (var x in this.redact.resultContents){
          let objredact: RedactReply   ;
         
          objredact= this.redact.resultContents[x];
       //   console.log(objredact.HtmlText.toString().replace("<o:p></o:p>",));
         this.copyText=this.copyText.concat( "Redacted Text " ); 
         this.copyText=this.copyText.concat(count.toString());
         this.copyText=this.copyText.concat(" :");
         this.copyText=this.copyText.concat('\n');
         this.copyText=this.copyText.concat(objredact.Text.toString().split("<br/>").join("\n"));
         this.copyText=this.copyText.concat('\n');
         this.copyText=this.copyText.concat('\n');
       count=count+1;
       
               }
        this.translate.use(this.redact.Language);
      localStorage.setItem('Langauge', this.redact.Language);
      this.logoId=this.redact.LogId; 
    if((this.redact.ViewsLeft=="0" ) ||(this.redact.IsRedactExpired==true))
   {
    this.subscription.unsubscribe()
   // setTimeout(()=>  this.subscription.unsubscribe(), 2000);
   this.router.navigate(['viewRemaining'],{skipLocationChange: true});
   }
      }
    });
  
   }
   getCountryDialCodeList(){
    this.subs.sink = this.user.getCountryDialCodeList()
    .subscribe(resp=> {
      if(!(resp instanceof AppError)){
        const response = resp as ICountryCodeDetails;
        // const result = response.ResultContent;
        this.countryCodeDetails = response;
      }
      })
    
   }
 
  redirectToTimeRemaining()
  {
   // setTimeout(()=>  this.subscription.unsubscribe(), 2000);
    this.router.navigate(['timeRemaining'],{skipLocationChange: true});
  }
  validEmail: boolean = true;
  validNumber: boolean = true;
  public copyText :string="";
  verifyEmail(form: FormGroup){
    if (this.loginForm.invalid) {

      if (this.loginForm.controls.emailId.errors)
        this.validEmail = false;
      return;
    }
    this.validEmail = true;
    let email=this.loginForm.controls.emailId.value;
    let phoneNumber= null;
    let countryDialCode = null;
    this.EmailVerification(email.toString(), phoneNumber, countryDialCode);
   
  }
  verifyNumber(form: FormGroup){
    if (this.loginFormSMS.invalid) {

      if (this.loginFormSMS.controls.phoneNumber.errors || this.loginFormSMS.controls.countryDialCode.errors){
        this.validNumber = false;
      }
      return;
    }

    this.validNumber = true;
    let email=null;
    let phoneNumber=this.loginFormSMS.controls.phoneNumber.value;
    let countryDialCode = this.loginFormSMS.controls.countryDialCode.value;
    this.EmailVerification(email, phoneNumber.toString(), countryDialCode.toString());
   
  }

  copyMessage(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.copyText;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copied=true;
    setTimeout(()=>{                           //<<<---using ()=> syntax
      this.copied = false;
 }, 3000);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe()
}

}
