import { Component, OnInit } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { switchMap, catchError, map } from 'rxjs/operators';
import { interval } from 'rxjs';
import { IBrand,IBrandHF} from '../common/ibrand';
import { RedactReplyServiceService } from '../service/redact-reply-service.service';
 import { AppError } from '../common/class/app-error';
// import { of, Observable } from 'rxjs';
// import { map, catchError } from 'rxjs/operators';
import { SubSink } from '../../../node_modules/subsink';
import {  HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { IDisappearingInkHF,IDisappearingInk,DisappearingInk } from '../class/DisappearingInk';
import{IEmailVefifyHF,IEmailVefify,EmailVefify,IBrandDetailsHF,IBrandDetails,BrandDetails, CountryCodeDetails, ICountryCodeDetails, ICountryDialCodeForSenderAddress} from '../class/redact';
import {IPostResponseHF, IPostResponse,PostResponse } from '../class/PostResponse';
import { Router ,ActivatedRoute} from '@angular/router';
import { timer, Subscription } from "rxjs";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { stringify } from 'querystring';
import { DestinationDetails } from '../class/DestinationDetails';
import { ResultContent } from '../class/AutoLockApiResponse';
import { AI_AutoLock_Access_Check } from '../service/AutoLock_Access_Check/AI_AutoLock_Access_Check';
import { AutoLockRequestModel } from '../class/AutoLockRequestModel';
import { SendAccessControlNoticeRequestModel } from '../class/SendAccessControlNoticeRequestModel';
import { Location } from '@angular/common';
import { AppConfigService } from '../app-config.service';
//import { debug } from 'console';

@Component({
  selector: 'app-disappear-ink',
  templateUrl: './disappear-ink.component.html',
  styleUrls: ['./disappear-ink.component.scss']
})
export class DisappearInkComponent implements OnInit {
// enableEdit: boolean = true;
mySubscription: Subscription;
logoId:string;
public viewsLeft:string;
public brandDetails=new BrandDetails;
selectedLanguage:string;
 hideElement = false;
 destShortURL:string;
 HeaderHtml: SafeHtml;
 public DI= new DisappearingInk;
 private subs = new SubSink();
 public copied=false;
 public emailVerfication=new EmailVefify;
 public countryCodeDetails = new CountryCodeDetails;
 loginForm: FormGroup;
 loginFormSMS: FormGroup;
 emailDispatchOption: number;
 isSMSOptionEnabled: boolean = false;
 isVerified=false;
 showLogo:boolean=true;
 companyLogoURL:string="";
 countDown:Subscription;
 confidentialityStatement:string;
 counter :number;
 tick = 1000;
 subscription: Subscription;
  isAutoLock_AccessDenied: boolean;
  accessDeniedText: string;
  isDisappearInk: boolean;
  contactSenderText: string;
  DialCodeForSenderAddress: string;
  productLine:string;
 constructor(private formBuilder: FormBuilder,private user: RedactReplyServiceService, private AI_Autolock_user: AI_AutoLock_Access_Check,private http: HttpClient,private router:Router, private route: ActivatedRoute
  ,private translate: TranslateService, private location: Location
  ,private config: AppConfigService) {
  
  this.route.params.subscribe( params => this.destShortURL=(params.shortURL));
  this.productLine = this.config.productLine;
  }
public conditionvalue:number=0;
//emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
//emailPattern="^(?!\\.)(?!\\')(?!\\^)(?!\\?)(?!\\])(?!\\*)(?!\\{)(?!\\})(?!\\[)(?!.*?[.]{2})|[#!_%&$^+-][a-z0-9.!#_%'&^$?!.\]*{}\[+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
//emailPattern="^(?!.*?[.]{2})|[a-z0-9.!#_%'&^$?!.\]/*{}\[|/~=+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
emailPattern = "^[a-zA-Z0-9.!#_%'&^$?\\-/+=]*[a-zA-Z0-9.!#_%'&^$?\\-/+=]+@[a-zA-Z\\u0080-\\uFFFF0-9_-]+\\.[a-zA-Z]{2,4}(?:\.[a-zA-Z]{2})?$";
phoneNumberPattern = "^[0-9+\\- ]+$";
ngOnInit() {
  this.getBrandingDetails();
  this.getCountryDialCodeList();
  this.loginForm = this.formBuilder.group({
    emailId:  [null, [Validators.required, Validators.pattern(this.emailPattern)]],
  });
  this.loginFormSMS = this.formBuilder.group({
    phoneNumber:  [null, [Validators.required,Validators.pattern(this.phoneNumberPattern)]],
    countryDialCode: [null, [Validators.required]]
  });
  var destDetails = new DestinationDetails();
    var resultContent = new ResultContent();

    // Detect client information
    this.detectClientInfo().then(clientInfo => {
      const { ipAddress, userAgent, referer, language } = clientInfo;
      console.log('Client Info:', ipAddress, userAgent, referer, language);

      // Get destination details independently
      this.AI_Autolock_user.getDestinationDetails(this.destShortURL).subscribe(
        destinationDetails => {
          if (destinationDetails) {
            destDetails.MessageId = destinationDetails.MessageId;
            destDetails.DestinationAddress = destinationDetails.DestinationAddress;
            destDetails.SenderAddress = destinationDetails.SenderAddress;
            destDetails.MessageLanguage = destinationDetails.MessageLanguage;
            destDetails.MessageSubject = destinationDetails.MessageSubject;
            destDetails.MessageCreatedDate = destinationDetails.MessageCreatedDate;

            // Prepare auto lock request model
            const autoLockRequest: AutoLockRequestModel = {
              IpAddress: ipAddress,
              SenderAddress: destDetails.SenderAddress,
              RecipientAddress: destDetails.DestinationAddress,
              //ProductLine: "RMail",
              ProductLine: this.productLine,
              ProductFeature: "DisappearingInk",
              ReferenceId: destDetails.MessageId,
              Http_UserAgent: userAgent,
              HTTP_Referer: referer,
              Browser_Language: language,
              RecipientType: 0,
              RecipientMobile: null
            };

            // Call auto lock access check
            this.AI_Autolock_user.getautoLockAccess(autoLockRequest).subscribe(
              autoLockResponse => {
                if (autoLockResponse && autoLockResponse.resultContent) {
                  resultContent = autoLockResponse.resultContent;

                  if (resultContent.lock) {
                    // Prepare access control notice request model
                    const accessControlNoticeRequest: SendAccessControlNoticeRequestModel = {
                      SenderAddress: destDetails.SenderAddress,
                      RecipientAddress: destDetails.DestinationAddress,
                      MessageId: destDetails.MessageId,
                      Location: `${resultContent.city}, ${resultContent.regionCode}`,
                      Country: resultContent.countryName,
                      Network: resultContent.network,
                      Feature: "RMail - Disappearing Ink",
                      OriginalSentDate: destDetails.MessageCreatedDate,
                      IpAddress: ipAddress,
                      ContinentCode: resultContent.continentCode,
                      AlertType: resultContent.riskLevel === 'Y' ? 'Yellow' : 'Red',
                      Language: destDetails.MessageLanguage,
                      Subject: destDetails.MessageSubject
                    };

                    // Send access control notice
                    this.AI_Autolock_user.sendAccessControlNotice(accessControlNoticeRequest).subscribe(
                      response => {
                        console.log('Access Control Notice Sent:', response);
                        this.isAutoLock_AccessDenied = true;
                        this.accessDeniedText = "Access Denied";
                        this.contactSenderText = "Please contact the sender for further information.";
                        this.isDisappearInk = true;
                        //this.router.navigate(['accessdenied']);
                        //this.router.navigateByUrl('/accessdenied', { skipLocationChange: false });

                        //const queryString = `?destShortURL=${encodeURIComponent(this.destShortURL)}&ViewType=DI`;
                        //this.location.go('/accessdenied');
                        //this.location.go(`/accessdenied/${this.destShortURL}`+"/DI");
                        this.router.navigateByUrl(`/accessdenied/${this.destShortURL}` + "/DI");
                        return;
                      },
                      error => {
                        console.error('Error sending access control notice:', error);
                      }
                    );
                  } else if (!resultContent.enabled) {
                    console.log('Autolock feature is not enabled');
                    this.isAutoLock_AccessDenied = false;
                    this.accessDeniedText = "";
                    this.loginForm = this.formBuilder.group({
                      emailId:  [null, [Validators.required, Validators.pattern(this.emailPattern)]],
                    });
                    this.loginFormSMS = this.formBuilder.group({
                      phoneNumber:  [null, [Validators.required,Validators.pattern(this.phoneNumberPattern)]],
                      countryDialCode: [null, [Validators.required]]
                    });
                  }
                  else {
                    console.log('Access is allowed');
                    this.isAutoLock_AccessDenied = false;
                    this.accessDeniedText = "";
                    this.loginForm = this.formBuilder.group({
                      emailId:  [null, [Validators.required, Validators.pattern(this.emailPattern)]],
                    });
                    this.loginFormSMS = this.formBuilder.group({
                      phoneNumber:  [null, [Validators.required,Validators.pattern(this.phoneNumberPattern)]],
                      countryDialCode: [null, [Validators.required]]
                    });
                  }
                }
                else {
                  this.isAutoLock_AccessDenied = false;
                  this.accessDeniedText = "";
                  this.loginForm = this.formBuilder.group({
                    emailId:  [null, [Validators.required, Validators.pattern(this.emailPattern)]],
                  });
                  this.loginFormSMS = this.formBuilder.group({
                    phoneNumber:  [null, [Validators.required,Validators.pattern(this.phoneNumberPattern)]],
                    countryDialCode: [null, [Validators.required]]
                  });
                }
              },
              error => {
                console.error('Error during auto lock access check:', error);
              }
            );
          }
          else {
            this.isAutoLock_AccessDenied = false;
              this.accessDeniedText = "";
            this.loginForm = this.formBuilder.group({
              emailId:  [null, [Validators.required, Validators.pattern(this.emailPattern)]],
            });
            this.loginFormSMS = this.formBuilder.group({
              phoneNumber:  [null, [Validators.required,Validators.pattern(this.phoneNumberPattern)]],
              countryDialCode: [null, [Validators.required]]
            });
          }
        },
        error => {
          console.error('Error getting destination details:', error);
        }
      );
    });

}
// async detectClientInfo(): Promise<{ ipAddress: string, userAgent: string, referer: string, language: string }> {
//     const ipAddress = await this.getIpAddress();//"183.82.7.117";//
//     const userAgent = navigator.userAgent;
//     const referer = document.referrer;
//     const language = navigator.language || (navigator as any).userLanguage;

//     return { ipAddress, userAgent, referer, language };
//   }

//   getIpAddress(): Promise<string> {
//     return this.http.get<{ ip: string }>('https://api64.ipify.org?format=json').pipe(
//       map(response => response.ip)
//     ).toPromise();
//   }

async detectClientInfo(): Promise<{ ipAddress: string, userAgent: string, referer: string, language: string }> {
  const serverVariables = await this.AI_Autolock_user.getServerVariables().toPromise();

  if (serverVariables) {
    const { IPAddress, UserAgent, Referer, BrowserLanguage } = serverVariables;
    return {
      ipAddress: IPAddress || null,
      userAgent: UserAgent || null,
      referer: Referer || null,
      language: BrowserLanguage || null
    };
  } else {
    return {
      ipAddress: null,
      userAgent: null,
      referer: null,
      language: null
    };
  }
}

 getDITextView() {
  this.subs.sink = this.user.postDITextView(this.destShortURL,"")
   // this.subs.sink = this.user.getDITextView(this.destShortURL)
   .subscribe(resp => {
    
            if (!(resp instanceof AppError)) {      
         
         const response = resp as IDisappearingInkHF;
          const result = response.ResultContent;
          this.DI=result;
     //     localStorage.setItem('redact', null);
   localStorage.setItem('disapperInk', JSON.stringify( this.DI));

        if( this.DI.LogoSrc==null ||this.DI.LogoSrc=="")
        {
                this.showLogo=false;
        }
        else
        {
         this.showLogo=true;
         this.companyLogoURL=this.DI.LogoSrc;
        }

        this.confidentialityStatement=this.DI.ConfidentialityStatement;

        const source = interval(this.DI.TimeAllowed* 1000);
        this.subscription = source.subscribe(val => this.redirectToTimeRemaining());
       
        let count :number=1;
      
        if(this.DI.DisappearingInkText != null)
        {
        // this.copyText=this.copyText.concat( this.DI.DisappearingInkCustomText ); 
        // this.copyText=this.copyText.concat(" :");
        //    this.copyText=this.copyText.concat('\n');
        //   this.copyText=this.copyText.concat(this.DI.DisappearingInkText);
           this.copyText=this.copyText.concat(this.DI.DisappearingInkText.toString().replace("<br/>","\n"));
           this.copyText=this.copyText.concat('\n');
        }
      this.translate.use(this.DI.Language);
      this.selectedLanguage=this.DI.Language;
     localStorage.setItem('Langauge', this.DI.Language);
                        this.logoId=this.DI.LogId; 
   if((this.DI.ViewsLeft=="0" ) ||(this.DI.IsDisappearingInkExpired==true))
  {
   this.router.navigate(['DIviewRemaining'],{skipLocationChange: true});
  }
     }
   });
 
  }
  getCountryDialCodeList(){
    this.subs.sink = this.user.getCountryDialCodeList()
    .subscribe(resp=> {
      if(!(resp instanceof AppError)){
        const response = resp as ICountryCodeDetails;
        // const result = response.ResultContent;
        this.countryCodeDetails = response;
      }
      })
    
   }

 redirectToTimeRemaining()
 {
   this.router.navigate(['DItimeRemaining'],{skipLocationChange: true});
 }
 public copyText :string="";

 copyMessage(){
   const selBox = document.createElement('textarea');
   selBox.style.position = 'fixed';
   selBox.style.left = '0';
   selBox.style.top = '0';
   selBox.style.opacity = '0';
   selBox.value = this.copyText;
   document.body.appendChild(selBox);
   selBox.focus();
   selBox.select();
   document.execCommand('copy');
   document.body.removeChild(selBox);
   this.copied=true;
   setTimeout(()=>{                           //<<<---using ()=> syntax
    this.copied = false;
}, 3000);
 }
 EmailVerification(emailID:string, phoneNumber:string, countryDialCode:string)
 {
   this.subs.sink = this.user.postUserVerification(this.destShortURL,emailID, phoneNumber,countryDialCode,"DI")
   .subscribe(resp => {
             if (!(resp instanceof AppError)) {      
         const response = resp as IEmailVefifyHF;
          const result = response.ResultContent;
          this.emailVerfication=result;
          if( this.emailVerfication.Verified.toString()=="true")
        {
          this.getDITextView();
              this.countDown = timer(0, this.tick)
             .subscribe(() => --this.DI.TimeAllowed)
             this.mySubscription= interval(60000).subscribe((x =>{
              if(  this.DI.TimeAllowed >0){
               this.subs.sink = this.user.apiDITextViewDuration(this.logoId)
               .subscribe(resp => {
               });
              }
           }));
      this.isVerified=true;
        }
        else{
          this.validEmail=false;
          this.validNumber = false;
        }
        
     //     localStorage.setItem('disapperInk',null);
            }});
   
 }
 validEmail: boolean = true;
 validNumber: boolean = true;
 verifyEmail(form: FormGroup){
   if (this.loginForm.invalid) {

    if (this.loginForm.controls.emailId.errors)
      this.validEmail = false;

   
    return;
  }
  this.validEmail = true;
  let email=this.loginForm.controls.emailId.value;
  let phoneNumber=null;
  let countryDialCode = null;
  this.EmailVerification(email.toString(),phoneNumber,countryDialCode);
 
}
verifyNumber(form: FormGroup){
  if (this.loginFormSMS.invalid) {

    if (this.loginFormSMS.controls.phoneNumber.errors){
      this.validNumber = false;
    }
    return;
  }
  this.validNumber= true;
  let email = null;
  let phoneNumber = this.loginFormSMS.controls.phoneNumber.value;
  let countryDialCode = this.loginFormSMS.controls.countryDialCode.value;
  this.EmailVerification(email,phoneNumber.toString(), countryDialCode.toString());
}
getBrandingDetails()
{
 this.subs.sink = this.user.postUserInformation(this.destShortURL,"DI")
  .subscribe(resp => {
           if (!(resp instanceof AppError)) {      
        const response = resp as IBrandDetailsHF;
         const result = response.ResultContent;
         this.brandDetails=result;
                this.translate.use(this.brandDetails.Language);
         localStorage.setItem('Langauge', this.brandDetails.Language);
         this.emailDispatchOption = this.brandDetails.EMailDispatchOption;
         this.isSMSOptionEnabled = this.emailDispatchOption == 2 ? true : false;
         if (this.isSMSOptionEnabled == true) 
           {
            this.subs.sink = this.user.postCountryDialCodeForSenderAddress(this.destShortURL)
            .subscribe(resp => {
              if(!(resp instanceof AppError)){
                const response = resp as ICountryDialCodeForSenderAddress;
                this.DialCodeForSenderAddress = response.DialCode;
              }
            })
           }
         if( this.brandDetails.LogoSrc==null ||this.brandDetails.LogoSrc=="")
         {
                 this.showLogo=false;
         }
         else
         {
          this.showLogo=true;
          this.companyLogoURL=this.brandDetails.LogoSrc;
         }}});
}
handleKeyUp(event: any)

{    

  if(event.keyCode === 13){  

      this.verifyEmail(event);    

    }

}
}